.add-new-case-div {
        min-height: calc(100vh - 48px);
        background-color: #F6F6F6;
        border-radius: 14px;
}

.add-new-case-card {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
}
.add-new-case-card .ant-card-body {
        padding-bottom: 0px;
}
.add-new-client-div .tabs-div {
        margin: 0 auto;
        width: 40%;
}

.add-new-case-card .tabs-div .ant-steps-item-icon {
        background-color: #FFFFFF;
        border: 1px solid #F6F6F6;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
}
.add-new-case-card .ant-steps-item-active .ant-steps-item-icon {
        background-color: #144ee3;
}
.add-new-case-card .ant-steps-item-title {
        font-weight: 400;
        font-size: 14px;
        color: #374151;
}
.add-new-case-card .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #11845B;
}
.add-new-case-card .ant-steps-item-finish .ant-steps-item-title {
        color: #11845B !important;
}
.add-new-case-card .ant-steps .ant-steps-item-title::after {
        height: 1.5px !important;
}
.add-new-case-card .ant-steps-item-finish .ant-steps-item-title::after {
        background-color: #11845B !important;
}
.add-new-case-card .ant-steps-item-finish .ant-steps-icon {
        color: #FFFFFF !important;
}

.add-new-case-div .form-div {
        margin: 0 auto;
        padding: 40px 0;
        width: 768px;
}

.add-new-case-div .form-div .form-card-title {
        margin-bottom: 24px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #374151;
        text-transform: capitalize;
}

.add-new-case-div .form-card {
        margin-bottom: 24px;
}

.add-new-case-div .form-card label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(55, 65, 81, 0.8);
}
.add-new-case-div .form-card input,
.add-new-case-div .form-card select {
        height: 48px;
        font-weight: 400;
        font-size: 14px;
        color: #374151;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
}

.add-new-case-div .form-card .add-btn {
        margin-bottom: 24px;
        color: #275CEF;
}

.add-new-case-div .form-card .ant-input-affix-wrapper {
        padding: 0;
        overflow: hidden;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
}
.add-new-case-div .form-card .ant-input-affix-wrapper .ant-input-prefix {
        padding: 0 20px;
        font-weight: 500;
        font-size: 14px;
        color: #6B7280;
        background-color: #F3F4F6;
}

.add-new-case-div .form-card .ant-input-affix-wrapper .remove-btn {
        padding: 0;
        margin-top: 2px;
        margin-right: 12px
}
.add-new-case-div .form-card .ant-input-affix-wrapper .remove-btn svg {
        font-size: 20px;
}

.add-new-case-div .form-card .address-space {
        width: 100%;
}
.add-new-case-div .form-card .address-space .ant-space-item {
        flex: 1;
}

.add-new-case-div .solutiontype-radio-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
}


.add-new-case-div .radio-desc-div {
        margin-left: -20px;
}

.add-new-case-div .solutiontype-radio-group .solutiontype-radio .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
        text-transform: capitalize;
}

.add-new-case-div .solutiontype-radio-group .solutiontype-radio hr {
        margin: 12px 0;
        width: 180px;
        border: none;
        height: 1px;
        background-color: #E5E7EB;
}
.add-new-case-div .solutiontype-radio-group .solutiontype-radio span.ant-radio {
        margin-top: 2px;
        align-self: flex-start;
}
.add-new-case-div .solutiontype-radio-group .solutiontype-radio {
        padding: 16px;
        border: 1px solid #E5E7EB;
        border-radius: 8px;
}

.add-new-case-div .solutiontype-radio-group .solutiontype-radio.ant-radio-wrapper-checked,
.add-new-case-div .plantype-radio-group .plantype-radio.ant-radio-wrapper-checked {
        border: 2px solid #144EE3;
}

.add-new-case-div .addon-checkbox-group {
        flex-wrap: wrap;
}
.add-new-case-div .addon-checkbox {
        margin: 0 24px 24px 0;
        width: calc(50% - 24px);
}

.add-new-case-div .ant-row {
        margin-bottom: 6px;
}

.add-new-case-div .type-label {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        font-weight: 500;
        color: #00000099;
        text-transform: capitalize;
}
.add-new-case-div .type-answer {
        padding-left: 18px;
        font-size: 13px;
        font-weight: 400;
        text-transform: capitalize;
        color: #00000099;
}

.add-new-case-div .plantype-radio {
        margin-bottom: 24px;
        padding: 16px;
        width: 100%;
        border: 1px solid #E5E7EB;
        border-radius: 8px;
}

.add-new-case-div .plantype-radio span.ant-radio+* {
        width: 100%;
}

.add-new-case-div .plantype-radio span.ant-radio {
        margin-top: 2px;
        align-self: flex-start;
}


.add-new-case-div .plantype-radio .top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.add-new-case-div .plantype-radio .top-section .title {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: #374151;
        text-transform: capitalize;
}
.add-new-case-div .plantype-radio .top-section .price {
        font-weight: 400;
        font-size: 14px;
}

.add-new-case-div .plantype-radio .top-section button {
        margin-right: 8px;
        font-weight: 400;
        font-size: 13px;
        color: #0B101B;
}

.add-new-case-div .plantype-radio .bottom-section {
        display: flex;
        height: 0px;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
}
.add-new-case-div .plantype-radio .bottom-section.open {
        margin-top: 16px;
        padding-top: 16px;
        height: 96px;
        border-top: 1px solid #E5E7EB;
}
.add-new-case-div .plantype-radio .bottom-section div {
        flex: 1;
}
.add-new-case-div .plantype-radio .bottom-section div.bottom-center {
        padding-left: 16px;
        border-left: 1px solid #E5E7EB;
        border-right: 1px solid #E5E7EB;
}
.add-new-case-div .plantype-radio .bottom-section div.bottom-right .ant-row {
        display: flex;
        flex-direction: column;
}
.add-new-case-div .plantype-radio .bottom-section div.bottom-right .type-label {
        padding-left: 18px;
}

.add-new-case-div .plantype-radio .bottom-section .bottom {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
}
.add-new-case-div .plantype-radio .bottom-section .bottom .ant-row {
        margin-bottom: 0;
        width: 33%;
        border-right: 1px solid #D9D9D9;
}
.add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(7),
.add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(8) {
        padding-left: 10px;
        border-right: none;
}
.add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(4),
.add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(5),
.add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(6) {
        padding-left: 10px;
}

.add-new-case-div .assets-collapse .ant-collapse-header,
.add-new-case-div .assets-collapse .ant-collapse-content-box {
        padding: 0;
}

.add-new-case-div .ant-upload .ant-upload-btn {
        padding: 24px;
}

.add-new-case-div .upload-div p.para {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4B5563;
}

.add-new-case-div .support-type {
        margin-top: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #6B7280;
}

.select-all-checkbox {
        float: right;
        margin-top: -45px;
}
.select-all-checkbox .ant-checkbox {
        display: none;
}
.select-all-checkbox span {
        font-weight: 400;
        color: #275CEF;
}

.category-checkbox-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
}
.category-checkbox-group .ant-checkbox-group-item {
        margin: 0 0 24px 0;
        padding: 16px 24px;
        width: 100%;
        height: fit-content;
        background: #F9FAFB;
        border: 1px solid #E5E7EB;
        border-radius: 8px;
        position: relative;
}

.category-checkbox-group .ant-checkbox-group-item .checkbox-btn {
        position: absolute;
        right: 0;
        top: 20%;
}
.category-checkbox-group .ant-checkbox-group-item .checkbox-btn span {
        color: #275CEF;
        font-weight: 400;
}

.category-checkbox-group .ant-checkbox-group-item span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
        text-transform: capitalize;
}

.category-checkbox-group .ant-checkbox-group-item .selected {
        max-width: 500px;
        margin-left: 40px;
}
.category-checkbox-group .ant-checkbox-group-item .selected span {

        font-size: 12px;
        text-transform: initial;
        color: grey;
}

.category-checkbox-group .ant-checkbox-group-item.checked {
        background: #F0F3FF;
        border: 2px solid #275CEF;
}

.subcat-modal .ant-modal-title {
        font-size: 18px;
        text-transform: capitalize;
}

.subcat-modal .ant-modal-body {
        min-height: 360px;
        max-height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
} 
.subcat-modal .ant-modal-body::-webkit-scrollbar {
        width: 0px;
}

.subcat-modal .ant-modal-body .ant-row {
        min-height: 360px;
}

.subcat-modal .ant-modal-body .loading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
}

.add-rule-card .ant-card-body {
        padding: 16px;
        display: flex;
}
.add-rule-card .ant-card-body button {
        margin: 0 auto;
        padding: 0;
        height: 24px;
}

.add-new-case-div .logo-input-div .ant-upload-wrapper {
        display: flex;
        align-items: center;
}

.add-new-case-div .logo-input-div .ant-upload-list-item-container {
        margin-left: 16px;
        border: 1.5px dashed #d9d9d9;
        border-radius: 6px;
        padding: 3px 10px;
        font-weight: 500;
}
.add-new-case-div .logo-input-div .ant-upload-list-item-container .ant-upload-icon,
.add-new-case-div .logo-input-div .ant-upload-list-item-container .ant-upload-list-item-progress {
        display: none;
}
.add-new-case-div .logo-input-div .ant-upload-list-item {
        margin-top: 0;
}

.add-new-case-div .document-card .ant-upload-list {
        display: none;
}

.add-new-case-div .document-card .file-details {
        margin-top: 16px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        border: 1.5px dashed #d9d9d9;
        border-radius: 8px;
}
.add-new-case-div .document-card .file-details button {
        padding: 0;
        color: red;
}

.success-modal .ant-modal-close-x {
        display: none !important;
}
.success-modal .ant-modal-body {
        text-align: center;
}
.success-modal .modal-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: -0.2px;
        color: #0B101B;
}

.form label {
        text-transform: capitalize;
}


/* mobile view */

@media screen and (max-width: 767px) {
        .add-new-client-div .tabs-div {
                width: 100%;
        }

        /* .add-new-client-div .tabs-div .ant-steps.ant-steps-vertical {
                flex-direction: row;
        } */
        .add-new-case-div .content-body {
                padding: 40px 24px;
        }
}