.visual-search-details-div .img-div {
        margin-bottom: 36px;
        padding: 16px;
        border: 1px solid #E6E6E6;
        border-radius: 14px;
}
.visual-search-details-div .img-div .uploaded-img {
        width: 188px;
        height: 144px;
        object-fit: cover;
        border-radius: 8px;
}

.visual-search-details-div .listing-row .listing-col .col-div {
        cursor: initial;
}


/* mobile view */
@media screen and (max-width: 767px) {
        .visual-search-details-div .img-div .uploaded-img {
                width: 100%;
                height: 100%;
        }
}