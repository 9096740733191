
.case-listings-div .loading {
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
}

.case-listings-div input {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #0B101B;
}

/* categories-tab-btn-div */

.case-listings-div .categories-tab-btn-div {
        margin: 16px -26px;
        padding: 9px 24px;
        height: 50px;
        background-color: #191E28;
        display: flex;
        align-items: center;
        justify-content: space-between;
}

.case-listings-div .categories-tab-btn-div .ant-tabs-nav {
        margin: 0;
}
.case-listings-div .categories-tab-btn-div .ant-tabs-nav::before {
        content: none;
}
.case-listings-div .categories-tab-btn-div .ant-tabs-tab {
        padding: 0;
}
.case-listings-div .categories-tab-btn-div .ant-tabs-nav-more {
        color: white;
}
.case-listings-div .categories-tab-btn-div .ant-tabs-tab .ant-tabs-tab-btn {
        padding: 2px 7px;
        color: #FFFFFF99;
        font-size: 13px;
        font-weight: 400;
}
.case-listings-div .categories-tab-btn-div .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #FFFFFF;
        font-weight: 600;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.1);
        border-radius: 6px;
}

.case-listings-div .categories-tab-btn-div .ant-tabs-ink-bar {
        background-color: transparent;
}

.case-listings-div .categories-tab-btn-div .filter-btn {
        padding: 4px;
        color: #FFFFFF;
        font-size: 13px !important;
        font-weight: 400;
}
.case-listings-div .categories-tab-btn-div .filter-btn svg {
        margin-right: 4px
}
.case-listings-div .categories-tab-btn-div .filter-btn svg path {
        fill: #FFFFFF;
}

.case-listings-div .categories-tab-btn-div .asset-dropdown .label {
        color: #FFF;
        text-align: right;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
}

.case-listings-div .categories-tab-btn-div .asset-dropdown .ant-select-selector {
        background-color: #191E28;
        border: none;
        color: #FFF;
        text-align: right;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        box-shadow: none;
}
.case-listings-div .categories-tab-btn-div .asset-dropdown .ant-select-selector .ant-select-selection-item {
        color: #FFFFFF !important;
}

.case-listings-div .categories-tab-btn-div .ant-select-arrow {
        color: #FFFFFF !important;
}

/* cta div */

.case-listings-div .cta-div {
        margin: 0 0 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
}

.case-listings-div .cta-div .list-type-toggle-div {
        padding: 4px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
}
.case-listings-div .cta-div .list-type-toggle-div button {
        border-radius: 8px;
}
.case-listings-div .cta-div .list-type-toggle-div button svg {
        margin-bottom: -2px;
}

.case-listings-div .cta-div .list-type-toggle-div button.active {
        background-color: #DBEAFE;
}
.case-listings-div .cta-div .list-type-toggle-div button.active svg path,
.case-listings-div .cta-div .list-type-toggle-div button.active svg rect {
        fill: #144EE3;
}

/* card */
.case-listings-div .listing-row .listing-col .col-div {
        height: 100%;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
}

.case-listings-div .listing-row .listing-col .col-div .flag-div {
        position: absolute;
        top: -40px;
        left: -40px;
        background-color: red;
        height: 80px;
        width: 80px;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: flex-end;
}
.case-listings-div .listing-row .listing-col .col-div .flag-div svg {
        fill: #FFFFFF;
        transform: rotate(-45deg);
        margin-right: 8px;
        margin-top: 4px;
}

.case-listings-div .listing-row .listing-col .col-div .list-checkbox {
        position: absolute;
        top: 15px;
        right: 15px
}

.case-listings-div .listing-row .listing-col .col-div .listing-img {
        width: 100%;
        height: 200px;
        object-fit: cover;
}

.case-listings-div .listing-row .listing-col .col-div .text-div {
        padding: 16px;
        color: #262628;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
}
.case-listings-div .listing-row .listing-col .col-div .text-div div {
        margin: 8px 0;
}

.case-listings-div .listing-row .listing-col .col-div .text-div .platform {
        display: flex;
        align-items: center;
}
.case-listings-div .listing-row .listing-col .col-div .text-div .platform .img-holder,
.case-listings-div .listing-row .listing-col .col-div .text-div .platform img {
        margin-right: 4px;
        height: 24px;
        width: 24px;
        background-color: lightgrey;
        border-radius: 100px;
}

.case-listings-div .listing-row .listing-col .col-div .text-div .title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
}
.case-listings-div .listing-row .listing-col .col-div .text-div .title p {
        width: 90%;
}

.case-listings-div .listing-row .listing-col .col-div .text-div .price p {
        color: #5F5F61;
        font-weight: 600;
}

.case-listings-div .listing-row .listing-col .col-div .text-div .date p {
        color: #5F5F6199;
}

.case-listings-div .ant-pagination-options {
        display: none;
}

/* modal */

.listing-details-modal {
        top: 50px;
}

.listing-details-modal .ant-modal-body {
        height: 78vh;
        overflow: auto;
}

.seller-details-modal .ant-modal-body {
        height: fit-content;
}

.listing-details-modal .listing-details-row {
        margin: 24px 0;
}

.listing-details-modal .listing-details-row .visual-search-btn {
        position: absolute;
        top: 10px;
        right: 12%;
        z-index: 100;
        cursor: pointer;
}

.listing-details-modal .preview-grid-div {
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
}
.listing-details-modal .preview-grid-div .preview-grid-item {
        margin: 12px;
        width: 72px;
        height: 72px;
        object-fit: cover;
        border: 2px solid transparent;
}
.listing-details-modal .preview-grid-div .preview-grid-item.preview-grid-item-active {
        border: 2px solid #144EE3;
}

.listing-details-modal .status-div {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
}

.listing-details-modal .status-div .ant-tag {
        padding: 4px 10px;
        font-weight: 600;
        font-size: 16px;
        color: #144EE3;
        text-transform: capitalize;
        background: #DBEAFE;
        border-radius: 4px;
        border: none;
}

.listing-details-modal .status-div .date {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: -0.05px;
        color: rgba(95, 95, 97, 0.6);
}

.listing-details-modal .title {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 28px;
        line-height: 140%;
        color: #374151;
}

.listing-details-modal .desc {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
}

.listing-details-modal .link {
        color: #144EE3;
        display: flex;
        align-items: center;
}
.listing-details-modal .link svg {
        margin-left: 4px;

}
.listing-details-modal .link svg path {
        fill: #144EE3;
}

.listing-details-modal hr {
        margin: 24px 0;
        border: none;
        height: 1px;
        background-color: #E5E7EB;
}

.listing-details-modal label {
        font-weight: 500;
}

.listing-details-modal p.line {
        line-height: 2em;
}

.listing-details-modal .ant-checkbox-group {
        display: flex;
        flex-direction: column;
}
.listing-details-modal .ant-checkbox-group .ant-checkbox-wrapper {
        margin: 0 0 12px 0;
        width: fit-content;
        font-weight: 400;
}

.listing-details-modal .no-steps-div {
        padding: 65px 0;
        text-align: center;
}
.listing-details-modal .no-steps-div p {
        margin: 16px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
}

.listing-details-modal .steps-div .steps {
        margin-left: 30px;
        margin-bottom: 16px;
        width: 55%;
        padding: 32px;
        border: 1px solid #E5E7EB;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        border-radius: 14px;
        position: relative;
}

.listing-details-modal .steps-div .steps .progress-indicator {
        position: absolute;
        left: -24px;
        top: 0;
        border-left: 1px solid #E5E7EB;
        height: calc(100% + 18px);
}
.listing-details-modal .steps-div .steps:last-child .progress-indicator {
        border-left: 1px dashed #E5E7EB;
}
.listing-details-modal .steps-div .steps .progress-indicator::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 12px;
        width: 12px;
        background-color: #6B7280;
        border-radius: 100%;
        transform: translateX(-50%);
}
.listing-details-modal .steps-div .steps:last-child .progress-indicator::before {
        background-color: #E5E7EB;
}

.listing-details-modal .collapse-steps .ant-collapse-header {
        padding: 0 !important;
}
.listing-details-modal .collapse-steps .ant-collapse-content-box {
        padding: 0 !important;
}
.listing-details-modal .collapse-steps .ant-collapse-content-box {
        padding: 16px 0 !important;
}
.listing-details-modal .collapse-steps .title {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #374151;
}
.listing-details-modal .collapse-steps .date {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(55, 65, 81, 0.8);
}
.listing-details-modal .collapse-steps .desc .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #374151;
}
.listing-details-modal .collapse-steps .desc .value {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #374151;
}

.listing-details-modal .add-activity-btn {
        color: #144ee3;
        margin-left: 30px;
        position: relative;
}
.listing-details-modal .add-activity-btn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -24px;
        height: 12px;
        width: 12px;
        background-color: #E4E4E7;
        border-radius: 100%;
        transform: translateX(-50%);
}


.ant-modal-body::-webkit-scrollbar {
        width: 0px;
}


/* listing table */

.listing-table .listing-title-cell {
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
}
.listing-table .listing-title-cell .flag-div {
        position: absolute;
        top: -30px;
        left: -30px;
        height: 60px;
        width: 60px;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(45deg);
}
.listing-table .listing-title-cell .flag-div svg {
        transform: rotate(-45deg);
        margin-top: 3px;
        margin-left: 39px;
}
.listing-table .listing-title-cell .listing-img-table {
        margin-right: 8px;
        height: 68px;
        width: 68px;
        border-radius: 8px;
}

.listing-number-alert {
        margin-bottom: 24px;
        border-radius: 8px;
        border: 1px solid #E5E7EB;
        background: #EAF4FF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        transform: all 0.2s ease-in-out;
}
.listing-number-alert.open {
        height: 80px;
        padding: 16px;
}
.listing-number-alert.close {
        margin-bottom: 0;
        height: 0;
        padding: 0;
        border: none;
}
.listing-number-alert .left-col {
        display: flex;
        align-items: center;
}
.listing-number-alert .left-col .icon {
        margin-right: 16px;
}
.listing-number-alert .left-col .text .label {
        color:#374151;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
}
.listing-number-alert .left-col .text .label span {
        margin-left: 8px;
        padding: 2px 6px;
        border-radius: 4px;
        color: #FFFFFF;
        background: #144EE3;
}
.listing-number-alert .left-col .text .desc {
        color: #6B7280;
        font-size: 14px;
        line-height: 20px;
}
.listing-number-alert .right-col .ant-btn {
        height: 36px;
        width: 36px;
        border-radius: 4px !important;
        border: 1px solid#E5E7EB;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08) !important;
}


.recrawl-radio-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
}
.recrawl-radio-group label {
        margin: 12px 0 12px 0;
        padding: 16px;
        width: calc(50% - 12px);
        color:#374151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border-radius: 8px;
        border: 1px solid #E5E7EB;
}
.recrawl-radio-group .ant-radio-wrapper-checked {
        border: 2px solid #144EE3;
}

/* import modal */
.import-modal .file-uploader .para {
        margin-bottom: 16px;
        color:#4B5563;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
}

.import-modal .support-type {
        margin-top: 16px;
        margin-bottom: 12px;
        color:#6B7280;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
}

.import-modal .ant-upload-list-item-container {
        display: none !important;
}

.import-modal .file-details {
        margin-bottom: 16px;
        padding: 2px 0px 2px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #4B5563;
        background-color: #00000005;
        border: 1px solid #E5E7EB;
        border-radius: 4px;
}
.import-modal .file-details svg {
        fill: #4B5563;
}


.filters-select .ant-select-selection-item {

        background-color: #DBEAFE !important;
        color: #2563EB !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        border: none !important
}
.filters-select .ant-select-selection-item svg {
        fill: #2563EB;
}


/* mobile view */

@media screen and (max-width: 767px) {
        .case-listings-div .header-row .header-left-col {
                flex-wrap: wrap;
        }

        .case-listings-div .case-select {
                margin-top: 12px;
                width: 47%;
                max-width: 47%;
        }
        .case-listings-div .search-space {
                margin-top: 12px;
                width: 100%;
        }
        .case-listings-div .search-space .ant-space-item {
                width: 100%;
        }

        .case-listings-div .categories-tab-btn-div {
                flex-wrap: wrap;
                height: auto;
        }
        .case-listings-div .categories-tab-btn-div .filter-btn svg {
                margin: 0;
        }

        .case-listings-div .cta-div {
                flex-wrap: wrap;
        }
        .case-listings-div .cta-div .cta-div-space-one {
                margin-bottom: 12px;
                width: 100%;
                justify-content: space-between;
        }

        .case-listings-div .cta-div .list-type-toggle-div {
                padding: 0;
        }

        .listing-details-modal .steps-div .steps {
                width: 89%;
        }
}